import { RichText, BLOCKS } from './components/richtextTypes'

export const validateInput = (
  input: string | RichText,
  setErrorFunc: Function,
): boolean => {
  if (typeof input === 'string') {
    if (input.length <= 3) {
      setErrorFunc('A title is required for every story')
      return false
    }
  } else {
    if (input.length === 1) {
      const firstBlock = input[0]
      if (
        firstBlock.type === BLOCKS.PARAGRAPH ||
        firstBlock.type === BLOCKS.HEADING ||
        firstBlock.type === BLOCKS.LIST_ITEM
      ) {
        if (
          firstBlock.children.filter(child => {
            return child.text.length > 0
          }).length === 0
        ) {
          setErrorFunc('Your story appears to be empty')
          return false
        }
      } else if (
        firstBlock.type === BLOCKS.NUMBERED_LIST ||
        firstBlock.type === BLOCKS.BULLETED_LIST
      ) {
        if (
          firstBlock.children.length === 1 &&
          firstBlock.children[0].children.filter(({ text }) => text.length > 0)
            .length > 0
        ) {
          setErrorFunc('Your story appears to be empty')
          return false
        }
      }
    }
  }
  return true
}
