import styled from '@emotion/styled'
import { BaseArg } from 'facepaint'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToggleOpenLoginModal } from '../context/LoginModalProvider'
import { useUser } from '../context/UserProvider'
import { mq } from '../styles/breakpoints'
import { colors } from '../styles/colors'
import { TitleBlock } from './TitleBlock'

const NAV_LINKS = [
  { label: 'Home', url: 'http://harrietalida.com' },
  { label: 'Books', url: 'http://harrietalida.com/books' },
  { label: 'Bylines', url: 'https://www.harrietalida.com/bylines/' },
  {
    label: 'Other Projects',
    url: 'https://www.harrietalida.com/other-projects/',
  },
  { label: 'Speaking', url: 'https://www.harrietalida.com/speaking/' },
  {
    label: 'The Forum',
    url: '/',
  },
  { label: 'Contact', url: 'https://www.harrietalida.com/contact/' },
]

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const HeaderWrapper = styled.div`
  display: flex;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  justify-content: center;
  flex: 1;
  background-color: ${colors.background};
  z-index: 1;
`

const HeaderContentDesktopTablet = styled.div`
  ${mq({
    display: ['none', 'flex', 'flex'],
    flex: 1,
    maxWidth: ['100%', '', '1053px'],
    flexDirection: 'row',
    justifyContent: 'space-between',
  })}
`
const HeaderContentMobile = styled.div`
  ${mq({
    display: ['flex', 'none', 'none'],
    flex: 1,
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  })}
`

const LogoHeader = styled.a`
  font-family: 'EB Garamond', Georgia, 'Times New Roman', serif;
  color: ${colors.titles};
  font-size: 30px;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 1em;
  padding: 0;
`

const LinksList = styled.ul`
  display: flex;
  list-style: none;
`

const ListItem = styled.li`
  display: flex;
`

const Link = styled.a<{ customStyles?: BaseArg }>(({ customStyles }) =>
  mq({
    margin: 'auto 22px auto 0',
    color: 'rgba(0, 0, 0, 0.6)',
    textDecoration: 'none',
    fontWeight: 600,
    wordWrap: 'break-word',
    cursor: 'pointer',
    ':hover': {
      color: 'rgba(0, 0, 0, 0.6)',
      textDecoration: 'none',
      opacity: '0.7',
      transition: 'all 0.4s ease-in-out',
    },
    ...customStyles,
  }),
)

const Main = styled.main`
  ${mq({
    width: '100%',
    margin: ['30px 0', '', '30px auto'],
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  })}
`

const MainContentWrapper = styled.div`
  ${mq({
    margin: ['0 20px', '0', '0'],
  })}
`

const NavBar: React.FC = () => {
  const toggleLoginModal = useToggleOpenLoginModal()
  const {
    user: { authenticated },
  } = useUser()
  const history = useHistory()

  const loginButtonText = authenticated ? 'Account' : 'Login'

  return (
    <HeaderWrapper>
      <HeaderContentDesktopTablet>
        <LogoHeader href="https://harrietalida.com">
          Harriet Alida Lye
        </LogoHeader>
        <LinksList>
          {NAV_LINKS.map(link => (
            <ListItem key={link.label}>
              <Link href={link.url}>{link.label}</Link>
            </ListItem>
          ))}
          <ListItem>
            <Link
              customStyles={{ width: '55px' }}
              href={authenticated ? '/profile' : '/login'}
              onClick={e => {
                e.preventDefault()
                if (!authenticated) toggleLoginModal()
                else history.push('/profile')
              }}
            >
              {loginButtonText}
            </Link>
          </ListItem>
        </LinksList>
      </HeaderContentDesktopTablet>
      <HeaderContentMobile>
        <LogoHeader href="https://harrietalida.com">
          Harriet Alida Lye
        </LogoHeader>
        <LinksList>
          <ListItem>
            <Link
              customStyles={{ width: '55px' }}
              href={authenticated ? '/profile' : '/login'}
              onClick={e => {
                e.preventDefault()
                if (!authenticated) toggleLoginModal()
                else history.push('/profile')
              }}
            >
              {loginButtonText}
            </Link>
          </ListItem>
        </LinksList>
      </HeaderContentMobile>
    </HeaderWrapper>
  )
}

export const Layout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <NavBar />
      {/* <HeaderSaveArea /> */}
      <Main>
        <TitleBlock title="THE FORUM" />
        <MainContentWrapper>{children}</MainContentWrapper>
      </Main>
    </Wrapper>
  )
}
