import { useMemo } from 'react'
import styled from '@emotion/styled'
import { gql, useQuery } from '@apollo/client'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Section } from '../../components/Section'
import { Spinner } from '../../components/Spinner'
import { StoryQuery } from './__generated__/StoryQuery'
import { RichText } from './components/RichText'
import { RichText as RichTextType } from '../newStory/components/richtextTypes'
import { colors, mq } from '../../styles'
import { FaInfinity } from 'react-icons/fa'
import { BiRadioCircle } from 'react-icons/bi'
import { EditStory } from './editStory'
import { useUser } from '../../context/UserProvider'
import { useToggleOpenLoginModal } from '../../context/LoginModalProvider'

export const STORY_FRAGMENT = gql`
  fragment StoryFragment on Story {
    id
    title
    body
    published
    publishedDate(format: "MMM Do, YYYY")
    shouldShowAuthor
    author
  }
`

export const STORY_QUERY = gql`
  query StoryQuery($id: String!) {
    getStory(id: $id) {
      ...StoryFragment
    }
  }
  ${STORY_FRAGMENT}
`

const Wrapper = styled.div`
  ${mq({
    maxWidth: ['100%', '90%', '600px'],
    width: '100%',
    alignSelf: 'center',
  })}
`

const StoryTitle = styled.h2`
  margin-bottom: 12px;
  text-align: center;
`

const Metadata = styled.div`
  margin-bottom: 0;
  text-align: center;
  font-family: 'EB Garamond', Georgia, 'Times New Roman', serif;
  font-weight: 900;
  font-size: 16px;
`

const NavArea = styled.div`
  margin: 0 auto 10px auto;
  display: flex;
  flex-direction: row;
`

const NavLink = styled(Link)`
  color: ${colors.altBackground};
  font-size: 18px;
`

const NavAnchor = styled.a`
  color: ${colors.altBackground};
  font-size: 18px;
`

const DividerIcon: React.FC = ({ ...props }) => {
  const random = Math.floor(Math.random() * 3)
  let icon
  if (random < 1)
    icon = (
      <>
        <BiRadioCircle size={30} style={{ marginRight: '-4px' }} />
        <BiRadioCircle size={30} style={{ marginLeft: '-4px' }} />
      </>
    )
  else if (random > 2) icon = <BiRadioCircle size={30} fill={'none'} />
  else icon = <FaInfinity size={30} />
  return <div {...props}>{icon}</div>
}

const Divider = styled(DividerIcon)`
  margin: 15px 0;
  text-align: center;
`

export const Story: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { user } = useUser()
  const history = useHistory()
  const toggleLoginModal = useToggleOpenLoginModal()
  const { data, loading, error } = useQuery<StoryQuery>(STORY_QUERY, {
    variables: {
      id: params.id,
    },
  })
  const story = data?.getStory
  const richText = useMemo(() => {
    if (story?.body) return JSON.parse(story.body) as RichTextType
    return null
  }, [story])

  if ((!loading && !story) || error) {
    return (
      <Section>
        <StoryTitle>Story not found</StoryTitle>
        <Divider />
        <p style={{ maxWidth: '500px', textAlign: 'center', margin: '0 auto' }}>
          Sorry, but it looks like the story you are trying to view does not
          exist. If this is your unpublished story that you are trying to view,
          please make sure that you are signed in.
        </p>
      </Section>
    )
  }

  if (story && !story.published) return <EditStory story={story} />

  return (
    <Section style={{ minHeight: '50vh' }}>
      <NavArea>
        <NavAnchor
          href="/story"
          onClick={e => {
            e.preventDefault()
            if (user.authenticated) history.push('/story')
            else toggleLoginModal()
          }}
        >
          Share your own story
        </NavAnchor>
        <BiRadioCircle size={5} style={{ margin: 'auto 10px' }} />
        <NavLink to="/">Read more stories</NavLink>
      </NavArea>
      {!story ? (
        <Spinner isLoading={true} />
      ) : (
        <Wrapper>
          <StoryTitle>{story.title}</StoryTitle>
          {story.shouldShowAuthor && <Metadata>{story.author}</Metadata>}
          <Divider />
          {richText && <RichText richText={richText} />}
        </Wrapper>
      )}
    </Section>
  )
}
