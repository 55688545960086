import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { mq } from '../styles/breakpoints'
import { colors } from '../styles/colors'

interface ITitleBlock {
  title: string
}

const BackgroundBlock = styled.div`
  background-image: linear-gradient(
    180deg,
    ${colors.titleBlockGrey} 0%,
    ${colors.titleBlockGrey} 100%
  ) !important;
  transform: skewY(2deg);
  width: 100%;
  padding-top: 6.25em;
  padding-bottom: 3em;
  max-width: 100vw;
  margin-top: -60px;
  margin-bottom: 40px;
`

const Title = styled.h1`
  text-align: center;
  color: white;
  font-family: 'EB Garamond', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 48px;
  letter-spacing: 3px;
  line-height: 1.3em;
  transform: skewY(-2deg);
  margin: 0 0 20px 0;
  ${mq({
    '& > a': {
      color: 'white',
    },
  })}
`

export const TitleBlock: React.FC<ITitleBlock> = ({ title }) => {
  return (
    <BackgroundBlock>
      <Title>
        <Link to="/">THE FORUM</Link>
      </Title>
    </BackgroundBlock>
  )
}
