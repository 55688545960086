import { createContext, useContext, useState } from 'react'

const LoginModalOpenContext = createContext(false)
const LoginModalSetOpenContext = createContext(() => {})

export const LoginModalProvider: React.FC = ({ children }) => {
  const [isOpen, toggleIsOpen] = useState(false)

  return (
    <LoginModalOpenContext.Provider value={isOpen}>
      <LoginModalSetOpenContext.Provider
        value={() => toggleIsOpen(isOpen => !isOpen)}
      >
        {children}
      </LoginModalSetOpenContext.Provider>
    </LoginModalOpenContext.Provider>
  )
}

export const useToggleOpenLoginModal = () =>
  useContext(LoginModalSetOpenContext)
export const useIsLoginModalOpen = () => useContext(LoginModalOpenContext)
