export const colors = {
  titles: '#333333',
  text: '#666666',
  background: '#ffffff',
  altBackground: 'rgba(122, 175, 191, 1)',
  titleBlockGrey: '#999999',
  buttonColor: '#2ea3f2',
  buttonHover: '#ACCDD7',
  linkActive: '#2ea3f2',
  link: 'rgba(0,0,0,.6)',
  linkHover: 'rgba(0,0,0,0.7)',
  error: '#dc3545',
  gainsboro: '#DCDCDC',
}
