import styled from '@emotion/styled'

import { IImage } from '../assets'
import { useProgressiveImg } from '../hooks'
import { API_ENDPOINT, DEV_API_ENDPOINT } from '../constants'
import { mq } from '../styles'

const url =
  process.env.NODE_ENV === 'development' ? DEV_API_ENDPOINT : API_ENDPOINT

interface IProps {
  image: IImage
}

interface IImgProps {
  blur: boolean
  imgSrc: string
}

const Img = styled.div<IImgProps>(({ blur, imgSrc }) =>
  mq({
    width: '100%',
    paddingTop: '60%',
    filter: blur ? 'blur(1px)' : 'blur(0px)',
    transition: 'filter 0.2s ease-in-out',
    borderRadius: '8px',
    background: `white url(${imgSrc}) no-repeat`,
    backgroundSize: 'cover',
    boxShadow: '6px 6px 14px 2px rgba(51,51,51,0.14)',
  }),
)

const Container = styled.div(() =>
  mq({
    width: ['95%', '45%', '30%'],
    padding: '10px',
  }),
)

export const Image: React.FC<IProps> = ({
  image: { dataUri, baseName, id, altText },
}) => {
  const { src, blur } = useProgressiveImg(
    `data:image/png;base64,${dataUri}`,
    `${url}/assets/${id}/medium-${baseName}`,
  )
  return (
    <Container>
      <Img blur={blur} imgSrc={src} />
    </Container>
  )
}
