export enum BLOCKS {
  PARAGRAPH = 'paragraph',
  NUMBERED_LIST = 'numbered-list',
  BULLETED_LIST = 'bulleted-list',
  LIST_ITEM = 'list-item',
  HEADING = 'heading',
}

export interface INLINE {
  text: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
}

export type Block =
  | {
      type: BLOCKS.PARAGRAPH | BLOCKS.LIST_ITEM | BLOCKS.HEADING
      children: INLINE[]
    }
  | {
      type: BLOCKS.NUMBERED_LIST | BLOCKS.BULLETED_LIST
      children: {
        type: BLOCKS.LIST_ITEM
        children: INLINE[]
      }[]
    }

export type RichText = Block[]
