import styled from '@emotion/styled'
import { gql } from '@apollo/client'
import { StoryCardFragment } from './__generated__/StoryCardFragment'
import { Link, useHistory } from 'react-router-dom'

import { mq } from '../../../styles/breakpoints'
import { colors } from '../../../styles'

export const STORY_CARD_FRAGMENT = gql`
  fragment StoryCardFragment on Story {
    id
    title
    author
  }
`

const Wrapper = styled.div`
  ${mq({
    width: ['95%', '45%', '30%'],
    padding: '10px',
    display: 'flex',
  })}
`

const Container = styled.div`
  ${mq({
    borderRadius: '8px',
    width: '100%',
    paddingTop: '60%',
    cursor: 'pointer',
    height: 0,
    position: 'relative',
    boxShadow: '6px 6px 14px 2px rgba(51,51,51,0.14)',
    ':hover,:focus,:active': {
      backgroundColor: colors.gainsboro,
    },
  })}
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const CardTitle = styled.h4`
  ${mq({
    fontSize: '22px',
    margin: '0',
    padding: '8px 8px 0 8px',
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  })}
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 8px;
`

const ByLine = styled.div`
  color: #333333;
`

const NavAnchor = styled.a`
  color: ${colors.altBackground};
  font-size: 18px;
`

interface IStoryCardProps {
  story: StoryCardFragment
}

export const StoryCard: React.FC<IStoryCardProps> = ({ story }) => {
  const history = useHistory()
  return (
    <Wrapper>
      <Container
        role="button"
        onClick={() => {
          history.push(`/story/${story.id}`)
        }}
        tabIndex={0}
      >
        <ContentWrapper>
          <CardTitle>{story.title}</CardTitle>
          <BottomRow>
            <ByLine>By: {story.author || 'Anonymous'}</ByLine>
            <NavAnchor
              href={`/story/${story.id}`}
              onClick={e => e.preventDefault()}
            >
              Read more
            </NavAnchor>
          </BottomRow>
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}
