import { gql, useQuery, useMutation } from '@apollo/client'
import {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { STORAGE_KEYS } from '../constants'
import { StorageService } from '../services/storageService'
import { LoginMutation } from './__generated__/LoginMutation'
import { LogoutMutation } from './__generated__/LogoutMutation'
import { Me } from './__generated__/Me'

interface IAuthenticatedUser {
  authenticated: boolean
  displayName?: string
}
type GetUserFunc = (credentials: string) => void

type UserType = {
  user: IAuthenticatedUser
  getUser?: GetUserFunc
  logoutUser?: () => Promise<void>
  fetching: boolean
}

export const ME_QUERY = gql`
  query Me {
    me {
      displayName
    }
  }
`

const LOGIN_MUTATION = gql`
  mutation LoginMutation($code: String!) {
    login(code: $code) {
      token
    }
  }
`

const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    logout
  }
`

const UserContext = createContext<UserType>({
  fetching: true,
  user: { authenticated: false },
})

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IAuthenticatedUser>({
    authenticated: false,
  })
  const [checkingNewToken, setCheckingNewToken] = useState(true)
  const { data, refetch, loading } = useQuery<Me>(ME_QUERY)
  const [login, { data: loginData }] = useMutation<LoginMutation>(
    LOGIN_MUTATION,
  )
  const [logout] = useMutation<LogoutMutation>(LOGOUT_MUTATION)

  useEffect(() => {
    if (data && data.me) {
      setUser({
        displayName: data.me?.displayName ?? undefined,
        authenticated: true,
      })
      setCheckingNewToken(false)
    }
    if (data && !data.me) {
      setUser({ authenticated: false })
      setCheckingNewToken(false)
    }
  }, [data])

  useEffect(() => {
    ;(async () => {
      if (loginData && loginData.login && loginData.login.token) {
        StorageService.save(STORAGE_KEYS.AUTH_KEY, loginData.login.token)
        await refetch()
      }
    })()
  }, [loginData, refetch])

  const getUser = useCallback(
    async (credentials: string) => {
      setCheckingNewToken(true)
      login({ variables: { code: credentials } })
    },
    [login],
  )

  const logoutUser = useCallback(async () => {
    StorageService.removeItem(STORAGE_KEYS.AUTH_KEY)
    await logout()
    refetch()
  }, [logout, refetch])

  const value = useMemo(() => {
    return { user, getUser, logoutUser, fetching: checkingNewToken || loading }
  }, [user, getUser, checkingNewToken, loading, logoutUser])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
