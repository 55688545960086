import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { ApolloProvider } from '@apollo/client'

import { client } from './services/apollo'

import { UserProvider } from './context/UserProvider'

import './styles/base.css'
import { LoginModalProvider } from './context/LoginModalProvider'

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <UserProvider>
          <LoginModalProvider>
            <App />
          </LoginModalProvider>
        </UserProvider>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
