import { useEffect, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import styled from '@emotion/styled'
import {
  useIsLoginModalOpen,
  useToggleOpenLoginModal,
} from '../context/LoginModalProvider'
import { useUser } from '../context/UserProvider'
import { Modal } from './Modal'

import { Spinner } from './Spinner'
import { FormInput } from './FormInput'
import { FormBooleanButtons, BOOLEAN_LABELS } from './FormBooleanButtons'
import { Button } from './Button'
import {
  CreateAuthorMutation,
  CreateAuthorMutationVariables,
} from './__generated__/CreateAuthorMutation'
import {
  RequestLoginMutation,
  RequestLoginMutationVariables,
} from './__generated__/RequestLoginMutation'
import { colors, mq } from '../styles'

export const CREATE_AUTHOR_MUTATUON = gql`
  mutation CreateAuthorMutation($createAuthorAuthorInputs: AuthorInputs!) {
    createAuthor(AuthorInputs: $createAuthorAuthorInputs)
  }
`

export const LOGIN_MUTATION = gql`
  mutation RequestLoginMutation($email: String!) {
    requestLogin(email: $email)
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid black;
  flex: 1;
  justify-content: space-between;
`

const H2 = styled.h2``

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const Error = styled.p`
  color: ${colors.error};
`

const NotLoggedIn: React.FC = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isNewUser, setIsNewUser] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [wasClicked, setWasClicked] = useState(false)
  const [isUnexpectedError, setIsUnexpectedError] = useState(false)
  const [shouldShowSuccessMessage, setShouldShowSuccessMessage] = useState(
    false,
  )
  const [register, { data: registerData }] = useMutation<
    CreateAuthorMutation,
    CreateAuthorMutationVariables
  >(CREATE_AUTHOR_MUTATUON)
  const [login, { data: loginData }] = useMutation<
    RequestLoginMutation,
    RequestLoginMutationVariables
  >(LOGIN_MUTATION)
  console.log({ loginData, registerData })
  useEffect(() => {
    if (loginData?.requestLogin || registerData?.createAuthor) {
      setShouldShowSuccessMessage(true)
    }
  }, [loginData, registerData])

  if (shouldShowSuccessMessage)
    return (
      <Content>
        <div>
          <H2>Thank you</H2>
          <p>
            We have sent instructions on how to login to{' '}
            <strong>{email}</strong>. Please click the link in the email to get
            started.
          </p>
        </div>
      </Content>
    )

  return (
    <Content>
      <div>
        <H2>Login to contribute</H2>
        {isUnexpectedError && (
          <Error>
            Something went wrong, please try again. If the error continues,
            please contact{' '}
            <a href="mailto:info@harrietalida.com">info@harrietalida.com</a>
          </Error>
        )}
        <FormInput
          label="Enter your email"
          value={email}
          onChange={e => {
            setEmail(e.target.value)
            if (emailError) setEmailError(false)
          }}
          validationFunc={() => {
            const isValid = validateEmail(email)
            if (!isValid) setEmailError(true)
          }}
          isValid={!emailError}
          errorMessage="A valid email is required"
        />
        <FormBooleanButtons
          value={isNewUser}
          onChange={value => setIsNewUser(value)}
          label="Is it your first time here?"
          optionLabels={BOOLEAN_LABELS.YES_NO}
        />
        {isNewUser && (
          <FormInput
            label="Enter your name to let us know who you are (optional)"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        )}
      </div>
      <Button
        styleOverride={{ marginLeft: '8px' }}
        onClick={() => {
          try {
            if (!wasClicked) {
              setWasClicked(true)
              if (isNewUser) {
                register({
                  variables: {
                    createAuthorAuthorInputs: {
                      email,
                      displayName: name,
                    },
                  },
                }).then(() => setWasClicked(false))
              } else {
                login({
                  variables: {
                    email,
                  },
                }).then(() => setWasClicked(false))
              }
            }
          } catch (err) {
            setIsUnexpectedError(true)
            setWasClicked(false)
          }
        }}
      >
        {isNewUser ? 'Register' : 'Login'}
      </Button>
    </Content>
  )
}

export const LoginModal: React.FC = () => {
  const isOpen = useIsLoginModalOpen()
  const toggleIsModalOpen = useToggleOpenLoginModal()
  const { user } = useUser()

  return (
    <Modal isOpen={isOpen} onRequestClose={toggleIsModalOpen}>
      {user.authenticated ? (
        <Spinner isLoading={true} />
      ) : user.authenticated ? (
        <div> Logout</div>
      ) : (
        <NotLoggedIn />
      )}
    </Modal>
  )
}
