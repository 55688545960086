import styled from '@emotion/styled'

import { mq } from '../styles'

export const Section = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: ['100%', '', '1050px'],
    margin: '0 auto',
    width: '100%',
  })}
`
