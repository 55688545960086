import styled from '@emotion/styled'

import { mq, colors } from '../styles'
import { FormWrapper } from './FormWrapper'

interface IProps {
  label: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any
  isValid?: boolean
  validationFunc?: () => void
  errorMessage?: string
}

interface IValidation {
  isValid: boolean
}

const Input = styled.input<IValidation>(({ isValid }) =>
  mq({
    border: '0',
    borderBottom: `1px solid ${isValid ? colors.text : colors.error}`,
    background: 'transparent',
    width: '100%',
    padding: '8px 0 5px 0',
    fontSize: '16px',
    color: isValid ? colors.text : colors.error,
    ':focus': {
      border: 'none',
      outline: 'none',
      borderBottom: `1px solid ${colors.altBackground}`,
    },
  }),
)

export const FormInput: React.FC<IProps> = ({
  label,
  value,
  onChange,
  isValid = true,
  validationFunc,
  errorMessage,
}) => {
  return (
    <FormWrapper isValid={isValid} label={label} errorMessage={errorMessage}>
      <Input
        value={value}
        onChange={onChange}
        isValid={isValid}
        onBlur={validationFunc}
        onFocus={onChange}
      />
    </FormWrapper>
  )
}
