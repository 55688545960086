import styled from '@emotion/styled'
import { colors, mq } from '../styles'
import { FormWrapper } from './FormWrapper'

export enum BOOLEAN_LABELS {
  YES_NO,
  TRUE_FALSE,
}

interface IProps {
  label: string
  value: boolean
  onChange: (value: boolean) => void
  optionLabels?: BOOLEAN_LABELS
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
`

const Button = styled.button<{ selected: boolean }>(({ selected }) =>
  mq({
    width: '75px',
    marginRight: '10px',
    border: `1px solid ${colors.buttonColor}`,
    borderRadius: '8px',
    backgroundColor: selected ? colors.buttonColor : 'transparent',
    padding: '8px',
    cursor: 'pointer',
    ':focus': {
      outlineColor: colors.buttonColor,
      outlineWidth: 2,
    },
    ':hover': {
      backgroundColor: selected || colors.buttonHover,
    },
  }),
)

export const FormBooleanButtons: React.FC<IProps> = ({
  label,
  value,
  onChange,
  optionLabels = BOOLEAN_LABELS.YES_NO,
}) => {
  const labels =
    optionLabels === BOOLEAN_LABELS.YES_NO ? ['Yes', 'No'] : ['True', 'False']

  return (
    <FormWrapper label={label}>
      <ButtonWrapper>
        <Button selected={value === true} onClick={() => onChange(true)}>
          {labels[0]}
        </Button>
        <Button selected={value === false} onClick={() => onChange(false)}>
          {labels[1]}
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  )
}
