import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client'

import { StorageService } from './storageService'
import { API_ENDPOINT, DEV_API_ENDPOINT, STORAGE_KEYS } from '../constants'

const httpLink = new HttpLink({
  uri: `${
    process.env.NODE_ENV === 'development' ? DEV_API_ENDPOINT : API_ENDPOINT
  }/graphql`,
})

const authLink = new ApolloLink((operation, forward) => {
  const token = StorageService.read(STORAGE_KEYS.AUTH_KEY)

  operation.setContext({
    headers: {
      authorization: token ? token : '',
    },
  })

  return forward(operation)
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
