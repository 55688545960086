import { STORAGE_KEY_PREFIX, STORAGE_KEYS } from '../constants'

const makeStorageKey = (key: STORAGE_KEYS) => `${STORAGE_KEY_PREFIX}${key}`

export const StorageService = {
  save: (key: STORAGE_KEYS, value: string, tempStorage: boolean = false) =>
    tempStorage
      ? sessionStorage.setItem(makeStorageKey(key), value)
      : localStorage.setItem(makeStorageKey(key), value),
  read: (key: STORAGE_KEYS) => {
    return (
      localStorage.getItem(makeStorageKey(key)) ||
      sessionStorage.getItem(makeStorageKey(key)) ||
      null
    )
  },
  swapToTemporary: (key: STORAGE_KEYS) => {
    const value = localStorage.getItem(makeStorageKey(key))
    if (!value) return false
    sessionStorage.setItem(makeStorageKey(key), value)
    localStorage.removeItem(makeStorageKey(key))
    return true
  },
  removeItem: (key: STORAGE_KEYS) => {
    localStorage.removeItem(makeStorageKey(key))
    sessionStorage.removeItem(makeStorageKey(key))
  },
  purgeStorage: () => {
    Object.values(STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(makeStorageKey(key))
      sessionStorage.removeItem(makeStorageKey(key))
    })
  },
}
