import { useEffect, useRef } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Layout } from './components/layout'
import { LoginModal } from './components/LoginModal'
import { useUser } from './context/UserProvider'
import { useQueryParams } from './hooks/'
import { Home } from './sections/home'
import { Profile } from './sections/profile'
import { NewStory } from './sections/newStory'
import { Story } from './sections/story'

function App() {
  const { user, getUser, fetching } = useUser()
  const { params, clearParam } = useQueryParams()
  const credentials = useRef(params.get('verify'))

  useEffect(() => {
    if (!fetching && !user.authenticated && credentials.current) {
      clearParam('verify')
      getUser?.(credentials.current)
      credentials.current = null
    }
  }, [fetching, user, getUser, clearParam])

  return (
    <>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/story">
            <NewStory />
          </Route>
          <Route path="/story/:id">
            <Story />
          </Route>
        </Switch>
      </Layout>
      <LoginModal />
    </>
  )
}

export default App
