import styled from '@emotion/styled'
import { Link, useHistory } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'

import { mq, colors } from '../../../styles'

import { Spinner } from '../../../components/Spinner'

import {
  MyStoriesQuery,
  MyStoriesQuery_myStories,
} from './__generated__/MyStoriesQuery'
import {
  DeleteStoryMutationVariables,
  DeleteStoryMutation,
} from './__generated__/DeleteStoryMutation'
import { STORY_FRAGMENT } from '../../story'
import {
  SetUnpublishedMutation,
  SetUnpublishedMutationVariables,
} from './__generated__/SetUnpublishedMutation'

interface IStoriesList {
  stories: MyStoriesQuery_myStories[]
}

export const SET_UNPUBLISHED_MUTATION = gql`
  mutation SetUnpublishedMutation($id: String!, $setPublish: Boolean!) {
    setIsPublishedStory(id: $id, setPublish: $setPublish) {
      ...StoryFragment
    }
  }
  ${STORY_FRAGMENT}
`

export const MY_STORIES_QUERY = gql`
  query MyStoriesQuery {
    myStories {
      id
      title
      published
      publishedDate(format: "MMM Do, YYYY")
    }
  }
`

export const DELETE_STORY_MUTATION = gql`
  mutation DeleteStoryMutation($id: String!) {
    deleteStory(id: $id)
  }
`

const StoriesSectionWrapper = styled.div`
  padding: 0 25px;
`

const StoriesTable = styled.table`
  ${mq({
    margin: '15px 0',
    borderCollapse: 'collapse',
    width: '100%',
    border: `1px solid ${colors.altBackground}`,
    borderRadius: '8px',
    thead: {
      backgroundColor: colors.altBackground,
      color: '#fff',
      th: {
        textAlign: 'left',
        padding: '12px 15px',
      },
      'th:first-child': {
        width: '50%',
      },
    },
  })}
`

const StoriesTr = styled.tr`
  ${mq({
    fontSize: '18px',
    padding: '8px 0',
    td: {
      padding: '12px 15px',
    },
    'td > a': {
      fontWeight: 'bold',
      fontSize: '20px',
    },
    'td:first-child': {
      cursor: 'pointer',
    },
    ':hover': {
      backgroundColor: '#fafafa',
    },
  })}
`

const h3Styles = {
  margin: '0',
}

const StoriesList: React.FC<IStoriesList> = ({ stories }) => {
  const history = useHistory()

  const [deleteMutation] = useMutation<
    DeleteStoryMutation,
    DeleteStoryMutationVariables
  >(DELETE_STORY_MUTATION, {
    update(cache, { data }) {
      const id = data?.deleteStory
      const existingStories = cache.readQuery<MyStoriesQuery>({
        query: MY_STORIES_QUERY,
      })
      if (existingStories && id) {
        cache.writeQuery({
          query: MY_STORIES_QUERY,
          data: {
            myStories: existingStories.myStories.filter(
              story => story.id !== id,
            ),
          },
        })
      }
    },
  })

  const [unpublishAndEdit] = useMutation<
    SetUnpublishedMutation,
    SetUnpublishedMutationVariables
  >(SET_UNPUBLISHED_MUTATION)

  return (
    <StoriesTable>
      <thead>
        <tr>
          <th>Story Name</th>
          <th>Published Date</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {stories.map(({ id, title, published, publishedDate }) => {
          return (
            <StoriesTr key={id}>
              <td onClick={() => history.push(`/story/${id}`)}>
                <Link to={`/story/${id}`}>{title}</Link>
              </td>
              <td>{published ? publishedDate : 'Not published'}</td>
              <td
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  unpublishAndEdit({
                    variables: {
                      id,
                      setPublish: false,
                    },
                  }).then(() => history.push(`/story/${id}`))
                }
              >
                <FaEdit />
              </td>
              <td
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  deleteMutation({
                    variables: {
                      id,
                    },
                  })
                }}
              >
                <a
                  href="/delete"
                  onClick={e => {
                    e.preventDefault()
                  }}
                >
                  <FaTrashAlt />
                </a>
              </td>
            </StoriesTr>
          )
        })}
      </tbody>
    </StoriesTable>
  )
}

export const StoriesSection: React.FC = () => {
  const { data, loading, error } = useQuery<MyStoriesQuery>(MY_STORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })
  const stories = data?.myStories
  if (error) return <p>Something went wrong...</p>
  if (!stories || stories.length <= 0)
    return <p>You have not shared any stories.</p>
  return (
    <StoriesSectionWrapper>
      <h3 style={h3Styles}>Your stories</h3>
      <StoriesList stories={stories} />
      <Spinner isLoading={loading} />
    </StoriesSectionWrapper>
  )
}
