// Import React dependencies.
import React, { useCallback, useMemo } from 'react'
import isHotkey from 'is-hotkey'
import styled from '@emotion/styled'
import { createEditor, Editor, Transforms } from 'slate'
import {
  useSlate,
  Slate,
  Editable,
  withReact,
  RenderElementProps,
  RenderLeafProps,
  ReactEditor,
} from 'slate-react'
import {
  FaBold,
  FaUnderline,
  FaItalic,
  FaListOl,
  FaListUl,
  FaHeading,
} from 'react-icons/fa'

import { Button, Toolbar } from './Toolbar'
import { colors, mq } from '../../../styles'

interface IProps {
  value: any[]
  setValue: (x: any) => void
}

type ButtonKeys =
  | 'bold'
  | 'underline'
  | 'italic'
  | 'list-ol'
  | 'list-ul'
  | 'heading'

const ICONS = {
  bold: FaBold,
  underline: FaUnderline,
  italic: FaItalic,
  'list-ol': FaListOl,
  'list-ul': FaListUl,
  heading: FaHeading,
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const StyledEditor = styled(Editable)`
  ${mq({
    minHeight: '300px',
    padding: '10px 20px',
    //border: '1px solid #fafafa',
    ':active,:focus': {
      //backgroundColor: '#fafafa',
    },
  })}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  padding: 15px 0;
  margin-bottom: 20px;
`

export const RTEditor: React.FC<IProps> = ({ value, setValue }) => {
  const editor = useMemo(() => withReact(createEditor()), [])
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    [],
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    [],
  )

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={newValue => setValue(newValue)}
    >
      <Wrapper>
        <Toolbar>
          <BlockButton format="heading" icon="heading" />
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          <BlockButton format="numbered-list" icon="list-ol" />
          <BlockButton format="bulleted-list" icon="list-ul" />
        </Toolbar>
        <StyledEditor
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={'What would you like to share?'}
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              // @ts-ignore
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                // @ts-ignore
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Wrapper>
    </Slate>
  )
}

function Element({
  attributes,
  children,
  element,
}: RenderElementProps): JSX.Element {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading':
      return <h3 {...attributes}>{children}</h3>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  let finalChildren: React.ReactNode = children
  if (leaf.bold) {
    finalChildren = <strong>{children}</strong>
  }

  if (leaf.code) {
    finalChildren = <code>{children}</code>
  }

  if (leaf.italic) {
    finalChildren = <em>{children}</em>
  }

  if (leaf.underline) {
    finalChildren = <u>{children}</u>
  }

  return <span {...attributes}>{finalChildren}</span>
}

const MarkButton = ({ format, icon }: { format: string; icon: ButtonKeys }) => {
  const editor = useSlate()
  const Icon = ICONS[icon]
  return (
    //@ts-ignore
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}

const BlockButton = ({
  format,
  icon,
}: {
  format: string
  icon: ButtonKeys
}) => {
  const editor = useSlate()
  const Icon = ICONS[icon]
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}

const toggleMark = (editor: Editor & ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: Editor & ReactEditor, format: string) => {
  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor: Editor & ReactEditor, format: string) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleBlock = (editor: Editor & ReactEditor, format: string) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type as string),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
