import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { colors, mq } from '../../../styles'

export const Button = styled.button<{ reversed?: boolean; active: boolean }>(
  ({ reversed, active }) =>
    mq({
      cursor: 'pointer',
      outlineColor: colors.altBackground,
      border: 'unset',
      color: reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc',
      marginBottom: '10px',
      padding: '6px 10px',
      borderRadius: '5px',
    }),
)

export const Menu = styled.div(() =>
  mq({
    '& > *': {
      display: 'inline-block',
    },
    '& > * + *': {
      marginLeft: '15px',
    },
  }),
)

export const Portal: React.FC = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

export const Toolbar = styled(Menu)`
  ${mq({
    position: 'relative',
    padding: '0 18px',
    borderBottom: '1px solid #eee',
  })}
`
