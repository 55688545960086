import styled from '@emotion/styled'
import { useState } from 'react'
import ReactModal from 'react-modal'

import { colors } from '../styles'

ReactModal.setAppElement('#root')

interface IModal {
  isOpen: boolean
  onRequestClose: () => void
  screenReaderLabel?: string
  shouldCloseOnOverlayClick?: boolean
}

export const Modal: React.FC<IModal> = ({
  children,
  isOpen,
  onRequestClose,
  screenReaderLabel,
  shouldCloseOnOverlayClick = true,
}) => {
  const { matches: isMobile } = window.matchMedia('(max-width: 800px)')
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: { zIndex: 2 },
        content: {
          zIndex: 2,
          width: isMobile ? '80%' : '40%',
          height: '60%',
          padding: isMobile ? '0' : undefined,
          margin: isMobile ? 'auto' : '15vh auto auto auto',
          display: 'flex',
          alignItems: 'stretch',
          top: isMobile ? 20 : undefined,
          bottom: isMobile ? 20 : undefined,
          left: isMobile ? 20 : undefined,
          right: isMobile ? 20 : undefined,
          position: isMobile ? 'absolute' : 'unset',
        },
      }}
      contentLabel={screenReaderLabel}
      shouldCloseOnOverlayClick={
        shouldCloseOnOverlayClick
        /* Boolean indicating if the overlay should close the modal */
      }
      onRequestClose={onRequestClose}
    >
      <>{children}</>
    </ReactModal>
  )
}
