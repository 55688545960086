import styled from '@emotion/styled'
import { CircleLoader } from 'react-spinners'
import { colors } from '../styles'

interface ISpinner {
  isLoading: boolean
  color?: string
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
`
const Container = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  margin: auto;
`

type SpinnerProps = ISpinner & WithStyleProps

export const Spinner: React.FC<SpinnerProps> = ({
  isLoading,
  css,
  color = colors.titleBlockGrey,
}) => {
  return (
    <Wrapper>
      <Container>
        <CircleLoader loading={isLoading} css={css} color={color} size={100} />
      </Container>
    </Wrapper>
  )
}
