import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

import { Section } from '../../components/Section'
import { useUser } from '../../context/UserProvider'
import { colors, mq } from '../../styles'

import { StoriesSection } from './components/StoriesList'
import { Button } from '../../components/Button'

const LogoutButton = styled(Button)`
  ${mq({
    width: ['100%', '150px', '200px'],
    fontWeight: 'bold',
    marginTop: '40px',
  })}
`

const NavLink = styled(Button)`
  ${mq({
    width: ['100%', '200px', '200px'],
    fontWeight: 'bold',
    marginTop: '40px',
  })}
`

const ButtonSection = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
  })}
`

export const Profile: React.FC = () => {
  const [logoutClicked, setLogoutClicked] = useState(false)
  const { user, logoutUser } = useUser()
  const history = useHistory()

  useEffect(() => {
    if (!user.authenticated) history.push('/')
  })

  return (
    <Section>
      <h2>Hello{user.displayName ? `, ${user.displayName}!` : '!'}</h2>
      <StoriesSection />
      <ButtonSection>
        <NavLink
          onClick={e => {
            e.preventDefault()
            history.push('/')
          }}
        >
          GO BACK TO THE FORUM
        </NavLink>
        <LogoutButton
          onClick={async () => {
            if (!logoutClicked) {
              setLogoutClicked(true)
              logoutUser?.()
            }
          }}
        >
          {logoutClicked ? 'Loading...' : 'LOGOUT'}
        </LogoutButton>
      </ButtonSection>
    </Section>
  )
}
