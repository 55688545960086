export interface IImage {
  id: string
  baseName: string
  dataUri: string
  altText: string
}

export const images: IImage[] = [
  {
    id: 'ckjhnc8wr0000v0uhhctmgqip',
    baseName: '2002-10-182-TnT-Walk-for-Harriet-scaled.png',
    dataUri:
      'iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADYUlEQVQozwFWA6n8AHhpUIRvV7OQcpN4ZL+Ze7uYer2Ze9m3l8+2nrWcgoZ1YG50eLCwvcTCzq+vu6Chq5WZoaChrpaYnIiDbgA+RzYoNSNcWUE9PyyPfF+EfGONfWPNp4fStpnGq49ya10aJiW3s7zQyda+u8a5t8S0sr+zssCqqaqtpZgAJzM1FSMeEBwaHCYgJzAqRE1GWGtRcn5eo5mCvq6hYWxwYWlu4NTezcXRwL3IwL7IvrvFv73JuLGqwbatABMgIRgjICY1LTRGREBMSUNRSE1nSFFvS3KDaKCnpHuXuczN2u/j5tXO183I1sjE0sK/zcPB0Lq0scS5rgALFxsVHx8hMiYlRjIzWkBVeFJwcllxd1xxfWNxd25rcnDq4efx5+u3t8Gur76ipriprLuztcO1sa60rZ4ADRcZDBkZFiolIUg1FDYpL0gwVFY7QUk7PEM0VlRKrqak//f+ycfQbXmGYXB/S2FxPFhoPVhqrKy3raeYAJ2ksZWcqIWOl4CPik5dX3FxenlpZJuamL+4u+fe5fzz/cnI04SNm3aAjnF+i1ttfTxaaiRAU1RgcqWijQBkgJl9kaaSn7FZXXBXYHSWmKJ5eYbQzdfCwtCiqLx+iqVxepN7hJR7hpRwfotYbHw9XG0lQ1caKzmLinAAOVx1SGd+OU1gHjFCXHOGcIKRPVVnV2N2XXCOX3GEYHSNSFhrUVdldoCPanyLVmx9OlttI0FUN0FGmJR1ADhZbkRkfypDVzJKXllyhZmjsqiyv1NsgllxiW2Ak2yDlld1jT1MV1ZmdGN5ilVugDtecjVQXURORVBraQBCYHY7XXciRmIwOk8+UmiJmq28wtF7j6Bie49vgZlmdIdSaH5AW25RbX9Zc4VOa31JbIBIWmcLFh0EHzEAS2d7PmF5LlpzGjlQEyAzJzxMRl5wXXWFXW2AQ1dsQ1loWHB/VnKFVXKHVHGDTGt+SWt+Umt5HCMoAggNAHyLnn6Km2FwgDlabzFMYik9TS1AUUJbcDJOXjhabU1tgVVzh1Ryhll1hlNwgURmeENneEBpejtYaR8qMwCoqLeVf4OIfIBaaHlGUl5DUFtgaHRKUFhtgI5dd4hHan1Qb4NUcoVVcYNKanw2XW81Xm8wXm0fUGUxT2VF83+7Kg4+3AAAAABJRU5ErkJggg==',
    altText:
      "Harriet's friends standing in the shape of a heart outside her window",
  },
  {
    id: 'ckjhnfrnn0001v0uh3gax29ge',
    baseName: '2002-10-31-Halloween03-Posy-scaled.png',
    dataUri:
      'iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC50lEQVQozwHcAiP9ABIkJwoVGhkzLxk6MSU8MS8/LTlSNk1nSCQyKg8sIw4fGwcPFg4eIg8jJBAkJUdtXFh5Z0hsV0tsWQcTGQAaMTUMGB0bPDMgRDktRTYzRy8/WTtYbk8rOjAUMzEKGh4CDBAKICQGISUXKClVdmZkgm9VdWFUcl8FEhgAHDU1EyMlIUc7NFlLOVRAOlM1T2VGYnJUXHSBYHiiX3SZNkRUNCEXRiUYQzcqYoFud455bYVvX3hkDhkcACA4NxQtMSFFOzleUT9dSkFZO1ZpSHCAg46b1ZOZ0Zif1Y+WxItpSXNuQHFVNGKAbXyRf3+SeldqWzo+PgAcN00TLkUfQjg7XFBEYk9FXkBabUlyhpaPm9qRmNaWn9uhn8lonYU4i7xDZ2peeGR9k4SJmoZZb2GBj4sADjZEDSNAGD43KVhLRmVUSGFAUWRZaHOTY2+7cXfBi47LgYmztrKjoZKbPmRZU3tpgpeHfJKEKD04mJ6aABo/QRIqQQwgJRc/PERkVUFcQH+MkoaKzGmA0XuCz42Q0Yyez5+Ij82nd5tsR2JyXnaXiF97b0BlU8fOxwAQLD4QLkcMGyMTNTY+Y1I+XE16iLyFi9GUmNienuKdndeJjtNpVn3BXzbgRCPASy5bdGg7XkxObmvY29oACR4vCxwoDSIrEjc2NlpKQlxibXS+hYbMpabQqKTVp6fakIu+T2aJcoZc0E0sx2FGMjsyVWxedIdl9vXyAAogMBAnLAoYIAURGB89Lzhca2dyv4iS242R4YOF3J2WuVNjtmpDWsVWKdNNL9O5sXSOfparorvBn/359gAcNDMdPTcSJCENGCFXbHCotsmoqdqspdqYlNqdlt+qpMBsdbZ+W4HSSizOSCa6kGzM0Mmcpp2BiH39+v0ALVRKIk9AQF9ikaS05Ojx/ff++vf79O737evz7uz1+PD69+/75+X13MvSw6OTwcOzqbGsYWdapaec////Efkt2kr/k1AAAAAASUVORK5CYII=',
    altText: 'Harriet with Posy the clown',
  },
  {
    id: 'ckjhnh8mw0002v0uha9dg4q2e',
    baseName: '2002-11-212-Microscope-Dr-Leung-scaled.png',
    dataUri:
      'iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADPklEQVQoz2Ng5RNlF5Bg5Rdj5xdl5xcDciGImVeEjU+US1CMU1AcKM4hIMbAJSQkJm2up8UOZLPxunh5MgDVcQpLswtKsPCJwHUKikppq6gA9WgqKkiISzDxCLPwCOsoK/lZGakoKLDwiTKw8voHBzKAVYMsYQNby8IryikgpqGkFOlgYaelEm6hl+PjEGxj7G1mODs9KN7FSlFOXkhMSlRUPDgilIGFVwSIpGXlOYUkgJoZeUSkpWWTXS2M1VX9TbQTbI1WV2Y2R3rn+zolOpgaqCr5Wxk6Guvz8gmGR0UwcApJ8YrKSqtow2wWAbqqNNit0NVYW0nBzdiwI9zD30SXnU+ER0A01dki0sEcqJlDVCq7IJtBUEpLQc1AREaRQ1AS6El2AXFpdb2W/JTTczpyvO3kRIRL3CzttDUYuIUFhCVCrY2V5BWMVOT9I2PLJs5iEJVRVdCz4ROX5RaRBgYsn6QSn7RqfVr0/+f3Xpw9tLqxYEaSf7CJDlAzj5A4t6A4C5+YsIhoZHp+0ZRVDAqaOrwS8kALeURluISlgC5n4BSYW1/w89qJr0/v/3r94telg12Z0cB44hCU4BIUZ+QR1lBRDi6szZ+wnAGolIGRg4GZi41flIlLiE1AnEFAyt3B5sv5A7+ePvx49dSWpqxACz12Tl5ZcSkTdVVzDRUVJeWo6q7UnoUMUTHJIWGx/kGhsirqogoq/rFJqSVVzqERyyc2vDi67dTKqdvaUueVJkzKjZmWF+pkqKEiKamgZ5bVPTugupshJDJu7pyF7V19bj4+aYWl3VNm17b1NE9ZbBUYoqCsKCwusWVK1f/n1/6+uvv/5s4dbbFRLroahmauGaXOJc0MIopa+RVVanrGTh5eDm4+3sFRrT3Tp85bX9ExVdbKk4GR19FC/8ORFT9vn7924uSeTetnteSqKCtyiiv6FtYzyKjqB0Yk8IpKM7DzM7BwcwmIdPXPmjN32ZTFm9VCCxk5+bmkVOdMnlTRutzAPkFA1ZFFVI2ZRwgoLqKkzWDj5G1o7sgqIAGMKqColYdf7+y1k2evTJ+wWsbUmYuXX8813dgphYNPnI2Ll42Xn51PGJicQCmKVwQA4LfQ9ovfO9QAAAAASUVORK5CYII=',
    altText: 'Harriet looking through a microscope at her own cells',
  },
  {
    id: 'ckjhniecg0003v0uh1r6vgkmj',
    baseName: '2002-12-02-Birthday-for-Mum-at-HSC-1-scaled.png',
    dataUri:
      'iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC50lEQVQozwHcAiP9AFdeZ5WVma2orLKpp+LSv//28f74+f/5/P/6/P/6/f/5+P3y6fns2/PdyOnOwNzGus+9tMO2tbCmnpKGegBjanKjoay1sru7tLjLva/r4ODu5uvv5urw5+r26u7Y0s2vrY734Mru1MbnzMXdx8LSwb7JvcC6r6iHYisAVWFni42YlZekoaGqlJGPsqmmzsPF2s7M2MzJ1cfG1Ma+08O168/G69LL5c7K3sjI08PEyr/Dwbe5iXhRADpMXVFeaHd/jXV6hio0NqagpNnO15SEgM7Awejc4NjIy5aIhZ6QhvDf3OPOzd/KzdTGysrAxsS9yqqiqABRYG+Kj6CoprGFhY5FREKYlp2XfnyKRy+7n5T68/jby8q5g2qpdWHZsq7n1dfcyc7SxczMwMm9u8qTlJ4ATGFzXm1+b3N2eICBQkZEAAgROi4sdUM3opeWxLOu28vL2bStq0c2pC0gwn553M7UzcLK08fOiI6sFzlZADxUbCEyQCg6Syo1PAAIDgILExIZITY4PWhjZL2Tf8mwptasrp0qGqMtH5kkFMGUks/I09HGzmFsnAIUKQBceJpRaIMPHSgBChEGDxcGDhYIDxYGDxWJgYLZr6HXu7LEp62MKx6kMCKcKhyZQDDHtb3Nx9Nud5gQLFUAJ0RhIjRHAQcMBg8WBA4VAAMJYmFlTU1St6GT3by05MzBqYNyfiUYnS8hmTAihCQUlltVxbzJjYufGDZhABcrQhAiMhIjLQUOFA8WHio0On+EoWVgftS8r+rc4ubQx3RhanMsJZouHZAuH4ItIHUkFaaNk4uKmQgWLwASIClmcYdDX5I3LTVqS0hXc5l6ibimjIWoo6+ywuO8nItLZqKHZ3CPJxKBLiB3LR9THhZ9VUtgXV8DEi4ASzZCX1uIFRc6QUlLQk1aWXyvb47NmISCbI3GdI69lIeMe53ZhpG/gkA3aCccbi0hORwaOCsrLS80Ehkikah4HtjQSqAAAAAASUVORK5CYII=',
    altText:
      "Harriet and her parents celebrating her mum's birthday in the hospital",
  },
]
