import styled from '@emotion/styled'

import { mq, colors } from '../styles'

interface IProps {
  label: string
  isValid?: boolean
  errorMessage?: string
}

interface IValidation {
  isValid: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
`

const Label = styled.label<IValidation>(({ isValid }) =>
  mq({
    color: isValid ? colors.text : colors.error,
    marginBottom: '5px',
  }),
)

const ErrorText = styled.div`
  color: ${colors.error};
`

export const FormWrapper: React.FC<IProps> = ({
  children,
  label,
  isValid = true,
  errorMessage,
}) => {
  return (
    <Wrapper>
      <Label isValid={isValid}>{label}</Label>
      {children}
      {!isValid && !!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Wrapper>
  )
}
