import { useCallback, useMemo, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export const useQueryParams = () => {
  const location = useLocation()
  const history = useHistory()
  if (!location)
    throw new Error('Trying to use query outside of react-router-dom')

  const clearParams = useCallback(() => {
    history.replace({ search: '' })
  }, [history])

  const params = useMemo(() => new URLSearchParams(location.search), [location])

  const clearParam = useCallback(
    (param: string) => {
      params.delete(param)
      history.replace({ search: params.toString() })
    },
    [params, history],
  )

  return {
    params,
    clearParam,
    clearParams,
  }
}

export const useProgressiveImg = (
  lowQualitySrc: string,
  highQualitySrc: string,
) => {
  const [src, setSrc] = useState(lowQualitySrc)

  useEffect(() => {
    setSrc(lowQualitySrc)

    const img = new Image()
    img.src = highQualitySrc

    img.onload = () => {
      setSrc(highQualitySrc)
    }
  }, [lowQualitySrc, highQualitySrc])

  return { src, blur: src === lowQualitySrc }
}
