import { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import styled from '@emotion/styled'
import { StoryCard, STORY_CARD_FRAGMENT } from './components/storyCard'

import {
  GetStoriesQuery,
  GetStoriesQuery_getStories,
} from './__generated__/GetStoriesQuery'
import { Section } from '../../components/Section'
import { useUser } from '../../context/UserProvider'
import { useHistory } from 'react-router-dom'
import { useToggleOpenLoginModal } from '../../context/LoginModalProvider'
import { colors, mq } from '../../styles'
import { IImage, images } from '../../assets'
import { Image } from '../../components/Image'
import { Spinner } from '../../components/Spinner'
import { Story } from '../story'

export const STORIES_QUERY = gql`
  query GetStoriesQuery {
    getStories(limit: 8) {
      ...StoryCardFragment
    }
  }
  ${STORY_CARD_FRAGMENT}
`

const NavLink = styled.a`
  color: #000000;
  font-family: 'EB Garamond', Georgia, 'Times New Roman', serif;
  border-radius: 8px;
  margin: 0 auto 15px auto;
  padding: 10px 50px;
  background-color: ${colors.altBackground};
  text-align: center;
  ${mq({
    fontSize: ['26px', '30px', '30px'],
  })}
`

const StoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const IntroWrapper = styled.p`
  ${mq({
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: "'EB Garamond', Georgia, 'Times New Roman', serif",
    fontSize: '18px',
    marginBottom: '30px',
  })}
`

interface IStory extends GetStoriesQuery_getStories {
  type: 'story'
}

interface IRenderImage extends IImage {
  type: 'image'
}

type RenderItem = IStory | IRenderImage

export const Home: React.FC = () => {
  const { user } = useUser()
  const history = useHistory()

  const [renderItems, setRenderItems] = useState<RenderItem[]>([])

  const toggleLoginModal = useToggleOpenLoginModal()
  const { loading, error, data } = useQuery<GetStoriesQuery>(STORIES_QUERY)
  useEffect(() => {
    if (data) {
      const dataLength = data.getStories.length
      const splitPos = Math.floor(Math.random() * dataLength)
      const stories: IStory[] = data.getStories.map(story => ({
        ...story,
        type: 'story',
      }))
      const firstSection = stories.splice(0, splitPos)
      const image = images[Math.floor(Math.random() * images.length)]
      setRenderItems([...firstSection, { type: 'image', ...image }, ...stories])
    }
  }, [data])
  if (error) return null
  return (
    <>
      <Section>
        <IntroWrapper>
          In 2002, when I was 15 and admitted to the Hospital for Sick Children
          for an indefinate period, my cousin in England set up a website where
          my parents and I could share updates of our life in the hospital. It
          was called The Forum. Here are some of my updates, as well as photos
          from the time. This page is a community space for you to share your
          own experience around illness. If you want, you can post anonymously.
        </IntroWrapper>
        <NavLink
          href={'/story'}
          onClick={e => {
            e.preventDefault()
            if (user.authenticated) history.push('/story')
            else toggleLoginModal()
          }}
        >
          SHARE YOUR STORY
        </NavLink>
        {loading || !data ? (
          <Spinner isLoading={true} />
        ) : (
          <StoriesWrapper>
            {renderItems.map(item => {
              if (item.type === 'story') {
                return <StoryCard key={item.id} story={item} />
              } else return <Image key={item.id} image={item} />
            })}
            {/* {data.getStories?.map(story => (
              <StoryCard key={story.id} story={story} />
            ))} */}
          </StoriesWrapper>
        )}
      </Section>
    </>
  )
}
