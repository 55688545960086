import { useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { useUser, ME_QUERY } from '../../context/UserProvider'
import { mq, colors } from '../../styles'
import { validateInput } from './validator'

import { RTEditor } from './components/Editor'
import { Section } from '../../components/Section'
import { Spinner } from '../../components/Spinner'
import { BLOCKS, RichText } from './components/richtextTypes'
import { Button } from '../../components/Button'

import {
  SaveStoryMutation,
  SaveStoryMutationVariables,
} from './__generated__/SaveStoryMutation'
import {
  UpdateAuthorMutation,
  UpdateAuthorMutationVariables,
} from './__generated__/UpdateAuthorMutation'

export const SAVE_STORY_MUTATION = gql`
  mutation SaveStoryMutation($addStoryStoryInputs: StoryInputs) {
    addStory(StoryInputs: $addStoryStoryInputs) {
      published
      shouldShowAuthor
      author
      body
      title
      id
      publishedDate(format: "MMM Do, YYYY")
    }
  }
`

export const UPDATE_AUTHOR_NAME = gql`
  mutation UpdateAuthorMutation($displayName: String!) {
    addName(displayName: $displayName) {
      displayName
    }
  }
`

const TitleInput = styled.input`
  ${mq({
    border: 'unset',
    borderBottom: '2px solid #eee',
    fontFamily: "'EB Garamond', Georgia, 'Times New Roman', serif",
    fontSize: '32px',
    marginBottom: '40px',
    ':focus,:active': {
      border: 'unset',
      borderBottom: `2px solid ${colors.altBackground}`,
      outline: 'none',
    },
  })}
`

const Error = styled.p`
  color: red;
  font-size: 12px;
  margin-top: -20px;
`

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const CheckWrapper = styled.div`
  ${mq({
    marginBottom: '20px',
    '& > *': {
      cursor: 'pointer',
    },
  })}
`

const NameInput = styled.input`
  ${mq({
    border: 'unset',
    borderBottom: '2px solid #eee',
    fontFamily: "'EB Garamond', Georgia, 'Times New Roman', serif",
    fontSize: '20px',
    maxWidth: ['100%', '', '30%'],
    marginBottom: '40px',
    ':focus,:active': {
      border: 'unset',
      borderBottom: `2px solid ${colors.altBackground}`,
      outline: 'none',
    },
  })}
`

export const NewStory: React.FC = () => {
  const [saveStory] = useMutation<
    SaveStoryMutation,
    SaveStoryMutationVariables
  >(SAVE_STORY_MUTATION)
  const [updateName] = useMutation<
    UpdateAuthorMutation,
    UpdateAuthorMutationVariables
  >(UPDATE_AUTHOR_NAME)

  const { user, fetching } = useUser()
  const history = useHistory()

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const [shouldShowAuthor, setShouldShowAuthor] = useState(false)
  const [authorName, setAuthorName] = useState('')
  const [rtEditorValue, setRtEditorValue] = useState<RichText>([
    {
      type: BLOCKS.PARAGRAPH,
      children: [{ text: '' }],
    },
  ])
  const [richTextError, setRichTextError] = useState('')

  const [isSaving, setIsSaving] = useState(false)

  if (!fetching && !user.authenticated) {
    history.push('/')
  }

  return (
    <Section>
      {fetching ? (
        <Spinner isLoading />
      ) : (
        <Wrapper>
          <TitleInput
            type="text"
            value={title}
            onChange={e => {
              setTitle(e.target.value)
              if (titleError) setTitleError('')
            }}
            placeholder="Enter a title for your story..."
          />
          {titleError.length > 0 && <Error>{titleError}</Error>}
          <RTEditor
            value={rtEditorValue}
            setValue={(val: RichText) => {
              setRtEditorValue(val)
              if (richTextError) setRichTextError('')
            }}
          />
          {richTextError.length > 0 && <Error>{richTextError}</Error>}
          <CheckWrapper>
            <input
              name="check"
              type="checkbox"
              checked={shouldShowAuthor}
              onChange={() => setShouldShowAuthor(cur => !cur)}
            />
            <label
              htmlFor="check"
              onClick={() => setShouldShowAuthor(cur => !cur)}
            >
              Include your name on your story?
            </label>
          </CheckWrapper>
          {shouldShowAuthor && !user.displayName ? (
            <NameInput
              value={authorName}
              onChange={e => setAuthorName(e.target.value)}
              placeholder="Add your name..."
            />
          ) : null}
          <ButtonsWrapper>
            <Button
              onClick={() => {
                if (!isSaving) {
                  setIsSaving(true)
                  const isValid =
                    validateInput(title, setTitleError) &&
                    validateInput(rtEditorValue, setRichTextError)
                  if (isValid) {
                    if (shouldShowAuthor && authorName && !user.displayName) {
                      updateName({
                        variables: {
                          displayName: authorName,
                        },
                        refetchQueries: [{ query: ME_QUERY }],
                      })
                    }
                    saveStory({
                      variables: {
                        addStoryStoryInputs: {
                          title,
                          body: JSON.stringify(rtEditorValue),
                          published: true,
                          shouldShowAuthor,
                        },
                      },
                    })
                      .then(() => history.push('/profile'))
                      .catch(err =>
                        console.error('Something went wrong: ', err),
                      )
                  }
                }
              }}
            >
              Publish story
            </Button>
            <Button
              onClick={() => {
                if (!isSaving) {
                  setIsSaving(true)
                  const isValid =
                    validateInput(title, setTitleError) &&
                    validateInput(rtEditorValue, setRichTextError)
                  if (isValid) {
                    if (shouldShowAuthor && authorName && !user.displayName) {
                      updateName({
                        variables: {
                          displayName: authorName,
                        },
                        refetchQueries: [{ query: ME_QUERY }],
                      })
                    }
                    saveStory({
                      variables: {
                        addStoryStoryInputs: {
                          title,
                          body: JSON.stringify(rtEditorValue),
                          published: false,
                          shouldShowAuthor,
                        },
                      },
                    })
                      .then(() => history.push('/profile'))
                      .catch(err =>
                        console.error('Something went wrong: ', err),
                      )
                  }
                }
              }}
            >
              Save draft
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      )}
    </Section>
  )
}
