import styled from '@emotion/styled'

import { mq, colors } from '../styles'

export const Button = styled.button<WithCustomStyleOverride>(
  ({ styleOverride }) =>
    mq({
      width: '150px',
      marginRight: '10px',
      border: `1px solid ${colors.buttonColor}`,
      borderRadius: '8px',
      backgroundColor: colors.buttonColor,
      padding: '8px',
      cursor: 'pointer',
      ':focus': {
        outlineColor: colors.buttonColor,
        outlineWidth: 2,
      },
      ':hover': {
        borderColor: colors.buttonHover,
        backgroundColor: colors.buttonHover,
      },
      ...styleOverride,
    }),
)
