import React from 'react'
import styled from '@emotion/styled'
import { mq } from '../../../styles'
import {
  RichText as RichTextType,
  BLOCKS,
  Block,
  INLINE,
} from '../../newStory/components/richtextTypes'

interface IProps {
  richText: RichTextType
}

const H3 = styled.h3`
  ${mq({
    marginTop: '0',
    '& > strong': {
      fontSize: '28px',
    },
    '& > *, & > * > *': {
      fontSize: '26px',
    },
  })}
`

const P = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
`

const Ol = styled.ol`
  margin-top: 0;
`

const Ul = styled.ul`
  margin-top: 0;
`

function renderInlines(leaf: INLINE, key: string | number): React.ReactNode {
  let res: React.ReactNode = leaf.text
  if (leaf.bold) res = <strong>{res}</strong>
  if (leaf.italic) res = <i>{res}</i>
  if (leaf.underline) res = <u>{res}</u>
  return <React.Fragment key={key}>{res}</React.Fragment>
}

function renderBlocks(block: Block, key: string | number) {
  if (block.type === BLOCKS.HEADING) {
    return (
      <H3 key={key}>
        {block.children.map((leaf, index) => renderInlines(leaf, index))}
      </H3>
    )
  }
  if (block.type === BLOCKS.PARAGRAPH) {
    return (
      <P key={key}>
        {block.children.map((leaf, index) => renderInlines(leaf, index))}
      </P>
    )
  }
  if (block.type === BLOCKS.LIST_ITEM) {
    return (
      <li key={key}>
        {block.children.map((leaf, index) => renderInlines(leaf, index))}
      </li>
    )
  }
  if (block.type === BLOCKS.BULLETED_LIST) {
    return (
      <Ul key={key}>
        {block.children.map((node, index) =>
          renderBlocks(node, `${node.type}-${index}`),
        )}
      </Ul>
    )
  }
  if (block.type === BLOCKS.NUMBERED_LIST) {
    return (
      <Ol key={key}>
        {block.children.map((node, index) =>
          renderBlocks(node, `${node.type}-${index}`),
        )}
      </Ol>
    )
  }
}

export const RichText: React.FC<IProps> = ({ richText }) => {
  return (
    <>
      {richText.map((block, index) =>
        renderBlocks(block, `${block.type}-${index}`),
      )}
    </>
  )
}
